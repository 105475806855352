import React, { useState } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'


const Crete = () => (
    <Layout className="crete details">
        <SEO title="About Crete" />
        <h1>Information about the island of Crete</h1>

        <div className="crete-container box-container">
            <h2>Travel tips</h2>
            <div className="details-item">
                <ul>
                    <li>“Hello“ and “Thank You“ in Greek: “Yasou” and “Efharisto”</li>
                    <li>Currency: Euro</li>
                    <li>Visa: Schengen visa.</li>
                    <li>Weather: Hot.</li>
                    <li>What to Pack: Summer clothes, swimsuit, a jacket or a scarf for cool nights.</li>
                    <li>Local delicacies: Everything! But you can specifically try <a href="https://flawlessfood.co.uk/wp-content/uploads/2022/01/Kleftiko-Greek-Lamb-284-of-415-Flawless-1024x683.jpg" target="_blank">lamb kleftiko</a>,  <a href="https://www.olivetomato.com/rakomelo-a-greek-traditional-warm-and-sweet-alcoholic-drink/" target="_blank">rakomelo</a> or tsikoudia,  <a href="https://www.mygreekdish.com/recipe/cretan-sweet-cheese-pastries-kalitsounia" target="_blank">kaltsounia</a>, which are delicacies local to Crete</li>
                </ul>
            </div>
            <div className="details-item">
                <p>
                    Find below some popular sites and beaches you can visit.
                    We have added travelling times from Elounda, but Crete is a big island.
                    If you have only a few days on the island it will be easier to visit sites that are 1-2 hours away maximum.
                </p>
                <p>
                    For the sites that are 3-4 hours away, you might want to consider staying in the beautiful town Chania
                    from where you can access some of the sites mentioned but with shorter travelling times.
                    Please also advise the maps linked for more exact information but also search google for exact directions.
                </p>
                <p>
                    Obviously, the sun in Greece is very hot, get a good sunscreen! Most beaches will have umbrellas set up that you can rent for the day. If you have sensitive skin maybe avoid the very hot hours between 12 - 3pm.
                </p>
            </div>
            <hr />
            <h2>Beautiful beaches near Elounda</h2>
            <div className="details-item">
                <h3>Beach Almyros </h3>
                <ul>
                    <li><a href="https://goo.gl/maps/5vDKFQJWGWhBim976" target="_blank">Map</a></li>
                    <li>Getting there: 15 min drive</li>
                    <li>A beautiful serene sandy beach, with lovely clean waters and a stream coming in with cold water that makes for a fun experience</li>
                </ul>
                <h3>Voulisma Beach</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/y1SAq2Kv4x9skPhh9" target="_blank">Map</a></li>
                    <li>Getting there: 35 min drive</li>
                    <li>Another beautiful sandy beach, with stunning blue waters. It has some bars music and service at the beach and can get busy in high season, but it should be a bit quieter in the end of May. Maybe try and get there a bit earlier in the day, to make sure you get a good spot</li>
                </ul>
                <h3>PETROS Water sports & Boat Rental</h3>
                <ul>
                    <li><a href="https://g.page/petros-water-sports-boat-rental?share" target="_blank">Map</a></li>
                    <li>Getting there: 5 min drive</li>
                    <li>At very small beach spot with a bar you can find Petros water sports. You can rent windsurfs and do some wakeboarding or water-skiing</li>
                </ul>
                <h3>Elounda Beach</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/TtVnAvsppBXecRTn7" target="_blank">Map</a></li>
                    <li>Getting there: Few minutes walk from Elounda's centre</li>
                    <li>Not our most favourite beach, but since it's so close to the village centre it's definitely wirth a mention as you can get there very quickly and have a nice refreshing dip.</li>
                </ul>
                <p>
                    There's a lot more beaches around Eloudna and Crete. 
                    The ones above are the most worth noting and accessible from Elounda. 
                    We are also mentioning below some more that are further away, but worth visiting if you have time. 
                </p>
            </div>
            <hr />
            <h2>Other Sites</h2>
            <div className="details-item">
                <h3>Spinalonga Island</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/tcL8mD1RbaxqUCr58" target="_blank">Map</a></li>
                    <li>Getting there: 8 min drive, and then take a boat around €8</li>
                    <li>During Venetian rule, salt was harvested from salt pans around Spinalonga island. The island has also been used as a leper colony  from 1903 to 1957. There's a historical fiction novel about this island called <a href="https://www.goodreads.com/book/show/922991.The_Island" target="_blank">The Island by Victoria Hislop</a></li>
                    <li><a href="https://greeking.me/blog/crete/item/255-the-story-of-spinalonga" target="_blank">More info</a></li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Winery tour, Lyrarakis Winery</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/zMpx4BqGGJvw6YTu9" target="_blank">Map</a></li>
                    <li>Getting there: 1h 30m drive</li>
                    <li><a href="https://www.arrivalguides.com/en/Travelguides/Europe/Greece/Crete/wineries" target="_blank">Info on Crete's Wineries</a></li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Knossos (archaeological site) - Iraklion</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/eiwCwuQKngTdb2Da8" target="_blank">Map</a></li>
                    <li>Getting there: 1h drive</li>
                    <li>Knossos is the largest Bronze Age archaeological site on Crete and has been called Europe's oldest city.
                        If you visit wear a hat, sunglasses and have some water as it can get very hot in the Greek Sun at this time of the year.</li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Elafonisi (beach) - SW Crete</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/Bfp1DRbkjjiDvHPX9" target="_blank">Map</a></li>
                    <li>Getting there: 4h drive!</li>
                    <li><a href="https://www.theguardian.com/travel/2022/mar/17/readers-favourite-coastal-walks-beach-paths-europe-hikes" target="_blank">
                        A pink sand beach
                    </a> voted many times as one of the most beautiful beaches in Europe.
                        Since this is further away from Elounda, it's worth a visit if you stay a bit longer in Crete.</li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Samaria Gorge (hike/gorge) - SW Crete</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/wmK9JQv8oEjsdsiu7" target="_blank">Map</a></li>
                    <li>Getting there: 4h drive!</li>
                    <li>Samaria National Park is a UNESCO Biosphere Reserve in the White Mountains of Crete and a part of the European E4 hiking trail.
                        The 15 km trail starts at the top of the gorge, taking visitors past towering cliffs, sweeping views,
                        and uniquely Cretan flora and fauna, eventually ending at the Libyan Sea.</li>
                    <li>More info <a href="https://www.cretanbeaches.com/index.php/en/gorges-and-canyons-in-crete/west-crete-gorges-chania/samaria-gorge" target="_blank">here</a> and <a href="https://www.kimkim.com/c/ultimate-guide-to-samaria-gorge" target="_blank">here</a></li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Balos Lagoon (hike/beach) - NW, Chania</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/uJNRMS7rbM4eytvs7" target="_blank">Map</a></li>
                    <li>Getting there: 4h drive</li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Chania’s Venetian Harbour (old town)</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/oNqbVkmgrwzp47Kp8" target="_blank">Map</a></li>
                    <li>Getting there: 3h drive</li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Arkadi Monastery - Rethimon</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/CPZk9AXDZF95bZRg9" target="_blank">Map</a></li>
                    <li>Getting there: 2h drive</li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Chrissi Island or Gaidouronisi (beach) - Ierapetra</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/kvV83meRQpn2yU136" target="_blank">Map</a></li>
                    <li>Getting there: 50 min drive to Ierapetra and a solid boat ride.</li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Falassarna (beach)</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/u2mMsqoBf8KsFNfp6" target="_blank">Map</a></li>
                    <li>Getting there: 3.5h drive</li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Phaistos Palace (archaeological site) -  SW Crete</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/SgMyk1ZbpbAMuUWN9" target="_blank">Map</a></li>
                    <li>Getting there: 1h 45 mins drive</li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Malia’s Minoan Palace (archaeological site, if not too much ruins)</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/SgMyk1ZbpbAMuUWN9" target="_blank">Map</a></li>
                    <li>Getting there: 40 min drive</li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Lychnostatis Open Air Museum</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/xch8he2hp6WQfmaP9" target="_blank">Map</a></li>
                    <li>Getting there: 50 mins drive</li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Aquaworld Aquarium & Reptile Rescue Centre - Hersonissos</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/xch8he2hp6WQfmaP9" target="_blank">Map</a></li>
                    <li>Getting there: 50 mins drive</li>
                </ul>
            </div>
            <div className="details-item">
                <h3>CRETAquarium Thalassókosmos - Heraklion</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/cXMehVQvbBexT36r7" target="_blank">Map</a></li>
                    <li>Getting there: 1h drive</li>
                    <li>An aquarium could be a good activity for kids</li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Watercity (Waterpark, Themed Park) - Hersonissos</h3>
                <ul>
                    <li><a href="https://goo.gl/maps/Bo2yMb151LGKXxhL8" target="_blank">Map</a></li>
                    <li>Getting there: 1h drive</li>
                </ul>
            </div>
            <div className="details-item">
                <h3>Other places you can research and explore</h3>
                <ul>
                    <li>Mount Ida or Psiloritis (hike) - Central Crete</li>
                    <li>Holy Trinity Monastery</li>
                    <li>Mirambello Bay</li>
                    <li>Rethymno Venetian Old Town</li>
                    <li>Heraklion Archaeological Museum</li>
                    <li>Psychro (Diktean) Cave - Central East, near Elounda</li>
                    <li>Kourtaliotiko Gorge - Central West, near Rethimno</li>
                    <li>Malia 😖 (if you really really wanna party)</li>
                    <li>Sitia Geopark - SE</li>
                </ul>
            </div>
        </div>
    </Layout>
)

export default Crete
